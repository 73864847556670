import PageBackground from 'Components/_common/PageBackground'
import { CONTEST_LIFE_STATES } from 'Components/ContestComponents/utils'
import { BackgroundPlug } from 'Components/Layout/MainLayout'
import { useSwipeHook } from 'hooks/useSwipe'
import { useBlogLastArticleQuery } from 'Queries/BlogArticles'
import { COMMUNITY_FAVORITE_LIFE_STATES, useCurrentCommunityFavoriteQuery } from 'Queries/CommunityFavorites'
import { COMMUNITY_FAVORITE_TITLE, CONTENT_TYPES } from 'Queries/constants'
import { useContestsQuery } from 'Queries/Contests'
import { getFlatPagesDataFromQueryData } from 'Queries/QueryDataUtils'
import { PROFILE_IMAGE_TYPE } from 'Queries/utils'
import React, { useEffect, useMemo, useState } from 'react'
import { When } from 'react-if'
import { COMMUNITY_FAVORITE_BACKGROUND_IMAGE_SRC } from 'Scenes/CommunityFavorites/constants'
import CustomContainer from 'Theme/CustomContainer'
import { getMsDate } from 'utils/date-utils'
import { userImageSelector } from 'utils/user-images-utils'

import HomeHeroDescription from './HomeHeroDescription'
import HomeHeroSliderControls from './HomeHeroSliderControls'

const { TYPE_ARTICLE, TYPE_CONTEST, TYPE_COMMUNITY_FAVORITE } = CONTENT_TYPES
const { VOTE } = COMMUNITY_FAVORITE_LIFE_STATES

const HomeHeroSection = () => {
  const { data: communityFavorites, isFetched: isFetchedCommFavorites } = useCurrentCommunityFavoriteQuery()
  const { data: contestsData, isFetched: isContestFetched } = useContestsQuery({
    _limit: 1,
    'state!': CONTEST_LIFE_STATES.PUBLISHED,
  })
  const { data: articles, isFetched: isArticlesFetched } = useBlogLastArticleQuery()

  // Mock fetch state for articles
  // eslint-disable-next-line no-unused-vars

  const article = useMemo(
    () => isArticlesFetched && { ...articles[0], contentType: TYPE_ARTICLE },
    [articles, isArticlesFetched],
  )

  const communityFavorite = useMemo(
    () => ({
      ...communityFavorites,
      image: COMMUNITY_FAVORITE_BACKGROUND_IMAGE_SRC,
      title: COMMUNITY_FAVORITE_TITLE,
      contentType: TYPE_COMMUNITY_FAVORITE,
    }),
    [communityFavorites],
  )

  const contests = getFlatPagesDataFromQueryData(contestsData)
  const contest = useMemo(
    () => ({
      ...contests[0],
      image: contests[0]?.contest_images && contests[0]?.contest_images[0]?.urls?.medium,
      contentType: TYPE_CONTEST,
    }),
    [contests],
  )

  const items = useMemo(() => {
    const sorting = (arr) => arr.sort((a, b) => getMsDate(b?.time_created) - getMsDate(a?.time_created))
    if (communityFavorite.state === VOTE) {
      return [communityFavorite, ...sorting([article, contest])]
    }
    return sorting([article, contest, communityFavorite])
  }, [article, contest, communityFavorite])

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedItem, setSelectedItem] = useState(items[0] || {})
  const [selectedFirstItem, setSelectedFirstItem] = useState(false)
  const { id: selectedItemId, image: selectedItemImage, contentType, state, vote_time_left } = selectedItem
  const isAllFetched = isFetchedCommFavorites && isContestFetched && isArticlesFetched
  const showSkeletons = !(isAllFetched && selectedItemId)

  useEffect(() => {
    if (isAllFetched && !selectedFirstItem) {
      setSelectedItem(items[0])
      setSelectedFirstItem(true)
    }
  }, [isAllFetched, items, selectedFirstItem])

  const handleSelect = (id) => {
    const item = items.find((favorite) => favorite.id === id)
    const index = items.findIndex((favorite) => favorite.id === id)

    setSelectedItem(item)
    setSelectedIndex(index)
  }

  const handleNext = (back = false) => {
    if (back) {
      if (selectedIndex === 0) {
        setSelectedIndex(items.length - 1)
        setSelectedItem(items[items.length - 1])
      } else {
        setSelectedItem(items[selectedIndex - 1])
        setSelectedIndex(selectedIndex - 1)
      }
    } else {
      if (selectedIndex === items.length - 1) {
        setSelectedIndex(0)
        setSelectedItem(items[0])
      } else {
        setSelectedItem(items[selectedIndex + 1])
        setSelectedIndex(selectedIndex + 1)
      }
    }
  }
  const layoutImage =
    selectedItem.contentType === TYPE_ARTICLE
      ? userImageSelector(article.news_images, PROFILE_IMAGE_TYPE.IMAGE, 'large')
      : selectedItemImage
  const [onTouchStart, onTouchMove, onTouchEnd] = useSwipeHook(
    () => handleNext(true),
    () => handleNext(),
  )
  const isCommVoteDone =
    contentType === CONTENT_TYPES.TYPE_COMMUNITY_FAVORITE && state !== COMMUNITY_FAVORITE_LIFE_STATES.VOTE
  const isCommVoteActive = !!vote_time_left && vote_time_left > 0 && !isCommVoteDone

  return (
    <>
      <When condition={!!layoutImage}>
        <BackgroundPlug
          id="home-hero-bg"
          deps={[layoutImage]}
        >
          <PageBackground
            styleName="homePage"
            height={570}
            imageElement={
              <img
                src={layoutImage}
                alt="community-favorite-background"
              />
            }
          />
        </BackgroundPlug>
      </When>

      <CustomContainer
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <HomeHeroDescription
          showSkeletons={showSkeletons}
          selectedItem={selectedItem}
          isCommVoteActive={isCommVoteActive}
          timeLeft={vote_time_left}
        />

        <HomeHeroSliderControls
          showSkeletons={showSkeletons}
          items={items}
          selectedItem={selectedItem}
          handleSelect={handleSelect}
          handleNext={handleNext}
        />
      </CustomContainer>
    </>
  )
}

export default HomeHeroSection
